#bloc-venir {
	background-repeat: no-repeat;
	background-position: center center;
  background-size: cover;

  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  color:#ffffff;

  a{
    margin-top: 20px;    
    color:#ffffff;
    text-decoration: underline;
    font-weight: $bold;
    font-size: 19px;
  }

  .office-tourisme {
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: $semiBold;
    font-size: 14px;
  }
}

@media (min-width: 992px) {
  #bloc-venir {
    height: 640px;
  
    a{
      font-size: 30px;
      line-height: 50px;
      letter-spacing: 1.67px;
      margin-top: auto;
      margin-bottom: auto;
    }
  
    .office-tourisme {
      margin-top: -120px;
      letter-spacing: 1.79px;
      line-height: 35px;
    }
  }  
}