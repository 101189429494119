#bloc-raisons {
  .slick-slider {
    .slide {
      h2 {
        font-weight: $bold;
        font-size: 12px;
        line-height: 20px;
        color: $orangeMilan;
        text-transform: uppercase;
      }

      p {
        font-weight: $regular;
        font-size: 12px;
        line-height: 20px;
        text-align: justify;
      }
    }

    .slick-dots {
      position: static;
      bottom: auto;
      margin-top: 10px;

      ul {
        padding:0;
      }

      li {
        width: auto;
        height: auto;
        margin: 0 10px;
      }

      .slick-active .button {
        color: $orangeMilan;
      }

      .button {
        font-weight: $extraBold;
        font-size: 24px;
        letter-spacing: 1.67px;
        line-height: 30px;
        text-decoration: none;
        color: #CECECE;
      }
    }
  }
}


@media (min-width: 992px) {
  #bloc-raisons {
    
    padding-top: 80px;
    padding-bottom: 80px;

    .slick-slider {
      .slide {
        h2 {
          font-weight: $bold;
          font-size: 14px;
          line-height: 30px;
          color: $orangeMilan;
          text-transform: uppercase;
          letter-spacing: 1px;
        }
  
        p {
          font-weight: $regular;
          font-size: 12px;
          line-height: 30px;
          letter-spacing: 1px;
        }
      }
  
      .slick-dots {
        position: static;
        bottom: auto;
        margin-top: -44px;
  
        ul {
          padding:0;
        }
  
        li {
          width: auto;
          height: auto;
          margin: 0 10px;
        }
  
        .slick-active .button {
          color: $orangeMilan;
        }
  
        .button {
          font-weight: $extraBold;
          font-size: 50px;
          letter-spacing: 2px;
          line-height: 30px;
          text-decoration: none;
          color: #CECECE;
        }
      }
    }
  }  
}