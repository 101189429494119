#bloc-video {
  height: 300px;
	background-repeat: no-repeat;
	background-position: center center;
  background-size: cover;

  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    font-weight: $bold;
    font-size: 19px;
    text-transform: uppercase;
    color:#ffffff;
    margin-top:30px;
  }

  img {
    width: 80px;
  }
}

@media (min-width: 992px) {
  #bloc-video {
    height: 640px;

    h1 {
      font-size: 30px;
      letter-spacing: 1px;
    }

    img {
      width: auto;
    }
  }  
}