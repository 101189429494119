#bloc-sponsors {

  margin-top: 20px;
  margin-bottom: 20px;

  h1 {
    margin:0;
    margin-bottom: 20px;
    font-size: 17px;
    font-weight:$bold;
    text-transform: uppercase
  }

  .slick-prev:before {
    content: "\f053";
  }

  .slick-next:before {
    content: "\f054";
  }  

  /*
  .slick-prev {
    left:50%;
    bottom: -50px;
    top: auto;
    transform: translate(-100%, 150%);
    z-index: 90;
    background: #ffffff;
    padding-left: 80px;
    padding-right: 30px;
  }


  .slick-next {
    right:50%;
    bottom: -50px;
    top: auto;
    transform: translate(100%, 150%);
    z-index: 90;
    background: #ffffff;
    padding-right: 80px;
    padding-left: 30px;
  }
  */

  .slick-prev:before, .slick-next:before {
    color:#4a4a4a;
    opacity: 1;
    font-family: "Font Awesome 5 Pro";
    font-size: 30px;
  }  

  .slick-prev, .slick-next {
    width: 30px;
    height: 61px;
  }

  .slick-prev {
    left: -18px;
  }

  .slick-next {
    right: -18px;
  }

  .slick-slide {
    padding: 10px;
  }
}

@media (min-width: 992px) {
  #bloc-sponsors {

    margin-top: 70px;
    margin-bottom: 87px;
  
    h1 {
      font-size: 30px;
      letter-spacing: 1px;
      margin-bottom: 30px;
    }

    .slick-prev:before, .slick-next:before {
      font-size: 60px;
    }    

    .slick-prev {
      left: -65px;
    }
  
    .slick-next {
      right: -65px;
    }

    .slick-slide {
      padding: 20px;
    }    
  }  
}