
#page-program {
    padding-top: 40px;
    padding-bottom: 40px;

    .submenu {
        display:flex;
        flex-direction: column;
        text-align: center;
        margin-bottom: 40px;

        .nav-link {
            text-transform: uppercase;
            color: #000000;
            font-weight: $bold;
            padding: .5rem 2px;
            font-size: 18px;
            line-height: 34px;

            &.active {
                color: $bleu3m;
                border-bottom: 4px solid $bleu3m;
            }
        }

    }

    .photo {
        text-align: center;
        .img {
            width: 100%;
            height: auto;
            max-width: 400px;
        }
    }

    .speaker {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        margin-bottom: 40px;
        max-width: 400px;
        margin: 0 auto;

        .infos {
            .name {
                text-transform: uppercase;
                color: $orangeMilan;
                font-weight: $bold;
                line-height: 40px;
                margin-bottom: 6px;
                font-size:15px;
            }

            .field_titre {
                font-size: 12px;
                color: #adadad;                
                font-weight: $medium;
            }

            .field_location {
                font-size: 12px;
                color: #adadad;
                line-height: 20px;                
                font-weight: $medium;
                margin-bottom: 20px;
            }

            .field_prix {
                font-size: 12px;
                color: #38373b;
                font-weight: $regular;
                margin-bottom: 20px;
            }

            .field_body {
                font-size: 12px;
                color: #adadad;
                
                font-weight: $semiBold;
                margin-bottom: 20px;

                p {
                    margin: 0;
                    line-height: 25px;
                }
            }

            .field_citation {
                font-size: 12px;
                color: #adadad;
                font-style: italic;
                font-weight: $regular;
                margin-bottom: 20px;
            }
        }
    }
}


@media (min-width: 992px) {
    #page-program {
        padding-top: 80px;
        padding-bottom: 60px;
    
        .submenu {
            margin-bottom: 60px;
            flex-direction: row;
    
            .nav-link {
                margin-right: 62px;
            }
    
        }
    
        .speaker {
            display: flex;
            flex: 1 1 auto;
            flex-direction: row;
            margin-bottom: 40px;            
        }

        .photo {
            .img {
                width: 200px;
                height: auto;
            }
        }

        .infos {
            padding-left: 20px;
        }
    }
    
}
