#bloc-saurel {
  padding-top: 20px;
  padding-bottom: 20px;

  .container {
    background-position: top center;
    background-repeat: no-repeat;
  }

  h2 {
    color :$vertMilan;
    font-weight: $bold;
    font-size: 13px;
    text-transform: uppercase;
  }

  .quote {
    font-weight: $regular;
    font-size: 12px;
    line-height: 20px;
    text-align: justify;
  }
  
  .presentation {
    background:#ffffff;
    margin-top: 250px;
    padding: 20px;
  }

  em {
    color :$vertMilan;
    font-weight: $semiBold;
    font-size: 12px;
    line-height: 25px;
    font-style: italic;
    letter-spacing: 0.86px;
  }
}

@media (min-width: 992px) {
  #bloc-saurel {
    padding-top: 64px;
    padding-bottom: 0;
    
    .container {
      background-position: center right;
    }

    h2 {
      font-size: 24px;
      line-height: 35px;
      letter-spacing: 1.34px;
      padding-right: 120px;
    }
  
    .quote {
      line-height: 30px;
      letter-spacing: 1px;
    }

    .presentation {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      margin: 0;
      padding: 0;
      padding-right: 20px;
      padding-top:55px;
      position:relative;
  
      background: -moz-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0.6) 100%);
      background: -webkit-linear-gradient(left, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,0.6) 100%);
      background: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,0.6) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#99ffffff',GradientType=1 );
  
      &:before {
        content: '';
        position:absolute;
        top:0;
        left:0;
        width: 83px;
        height: 52px;
        background:url('../assets/img/guillemets.svg');
      }
    }
  }  
}