.marker {
  border-radius: 50%;
  border: 5px solid $vertMilan;
  width: 16px;
  height: 16px;
  margin-top: -16px;

  box-shadow: 0px 14px 24px 1px rgba(0,0,0,.3);

  &:after {
    position: absolute;
    content: '';
    width: 0px;
    height: 0px;
    top: -5px;
    left: 0px;
    border: 8px solid transparent;
    border-top: 16px solid $vertMilan;
  }
}

#bloc-contact {
  background:#F8F8F8;
  padding-bottom: 50px;
  padding-top: 50px;
  position: relative;

  font-weight: $medium;
  font-size: 12px;
  letter-spacing: 0.86px;
  line-height: 28px;

  h2 {
    color: $bleu3m;
    font-size: 22px;
    font-weight: $bold;
    letter-spacing: 1.22px;
    line-height: 40px;
  }

  p {
    letter-spacing: 1px;
  }

  .col:first-child {
    margin-bottom: 50px;
  }

  /* Set the size of the div element that contains the map */
  #map {
    height: 400px;  /* The height is 400 pixels */
    width: 100%;  /* The width is the width of the web page */
  }

  .form {
    max-height: 800px;
    //transition: max-height 300ms ease-in-out;
    overflow: hidden;
  }

  .form-loading {
    max-height: 0;
  }

  .error {
    order: -1;
    color: $orangeMilan;
  }

  .field-textarea {
    .error {
      order: initial;
    }
  }


  .Modal {
    /*
    position: absolute;
    top: 40px;
    left: 40px;
    right: 40px;
    bottom: 40px;
    */
    margin: 100px auto;
    max-width: 900px;
    background: #ffffff; 
    box-shadow: rgba(0, 0, 0, 0.2) 0 0 20px -10px;
    border-radius: 5px;
  }
  
  .Overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255,255,255,.8);
    z-index: 1001;
  }
  
  .thank {

    padding-top: 40px;
    padding-bottom: 40px;

    .fal {
      font-size: 80px;
    }

    .btn-link {
      text-decoration: underline;
      color: $bleu3m;
    }

    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  
    text-align: center;
    color: $bleu3m;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 28px;;  
  }

  .loading{
    color: $bleu3m;
  }

}

/**
* Make the field a flex-container, reverse the order so label is on top.
*/

.field {
  display: flex;
  flex-flow: column-reverse;
  margin-bottom: 1em;
}

.field-textarea, .field-radio {
  display: flex;
  flex-flow: column;
  margin-bottom: 1em;
}
/**
* Add a transition to the label and input.
* I'm not even sure that touch-action: manipulation works on
* inputs, but hey, it's new and cool and could remove the
* pesky delay.
*/

label, input, select, textarea {
  transition: all 0.2s;
  touch-action: manipulation;
}

input, select, textarea {
  border: 0;
  border-bottom: 1px solid #ccc;
  font-family: inherit;
  -webkit-appearance: none;
  border-radius: 0;
  padding: 0;
  cursor: text;
  background:#F8F8F8;
}

.field-checkbox input {
  width: 15px;
  height: 15px;
  background-color: #FFFFFF;
  border: 1px solid #D8D8D8;
  margin-right: 8px;
  cursor: pointer;

  &:checked {
    background: #38373B !important;
    box-shadow: inset #ffffff 0px 0px 0px 2px;
  }

  &:focus {
    border-bottom: 1px solid #ccc;
  }
}

.field-radio input {
  width: 15px;
  height: 15px;
  background-color: #FFFFFF;
  border: 1px solid #D8D8D8;
  margin-right: 8px;
  border-radius: 15px;
  cursor: pointer;

  &:checked {
    background: #38373B !important;
    box-shadow: inset #ffffff 0px 0px 0px 2px;
  }

  &:focus {
    border-bottom: 1px solid #ccc;
  }
}

select {
  font-weight: $medium;
  padding-left:5px;
}

input:focus, select:focus {
  outline: 0;
  border-bottom: 1px solid #666;
}

.form-uppercase label {
  text-transform: uppercase;
  letter-spacing: 1px;
  //letter-spacing: 0.05em;
}
/**
* Translate down and scale the label up to cover the placeholder,
* when following an input (with placeholder-shown support).
* Also make sure the label is only on one row, at max 2/3rds of the
* field—to make sure it scales properly and doesn't wrap.
*/
input:placeholder-shown + label {
  cursor: text;
  max-width: 66.66%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transform-origin: left bottom;
  transform: translate(0, 2.125rem);// scale(1.1);
  margin-left:5px;
}
/**
* By default, the placeholder should be transparent. Also, it should
* inherit the transition.
*/
::-webkit-input-placeholder {
  opacity: 0;
  transition: inherit;
}
/**
* Show the placeholder when the input is focused.
*/
input:focus::-webkit-input-placeholder {
  opacity: 1;
}
/**
* When the element is focused, remove the label transform.
* Also, do this when the placeholder is _not_ shown, i.e. when
* there's something in the input at all.
*/
input:not(:placeholder-shown) + label,
input:focus + label {
  transform: translate(0, 0);// scale(.9);
  cursor: pointer;
}

.leaflet-container {
  height: 400px;
}

@media (min-width: 992px) {
  .leaflet-container {
    width: 80%;
    margin: 0 auto;
  }

  #bloc-contact {
    .col:first-child {
      margin-bottom: 0;
    }
  }
}

.has-error {
  input, select, textarea {
    border-bottom: 1px solid $orangeMilan;
  }
  select, label {
    color: $orangeMilan;
  }
}
