#bloc-engagement {
  margin-top: 30px;
  padding-bottom: 130px;

  h1 {
    text-align: center;
    margin:0;
    margin-bottom: 30px;
    font-size: 12px;
    font-weight:$bold;
    letter-spacing: 0.71px;
    line-height: 22px;
  }

  h3 {
    background: #5f9ea0;
    color: #fff;
    font-size: 36px;
    line-height: 100px;
    margin: 10px;
    padding: 2%;
    position: relative;
    text-align: center;
  }

  .slick-slide {
    .picture{
      img {
        width: 100%;
      }
    }

    .text {
      h2 {
        margin:0;
        color:$jauneMilan;
        font-size: 100px;
        font-weight: $bold;
        margin-top: -60px;
      }

      p {
        font-size: 12px;
        font-weight: $regular;
        text-transform: uppercase;
        line-height: 25px;
      }
    }    
  }

  .slick-engagements {
    position: relative;

    &:after {
      position:absolute;
      content: '';
      left:200px;
      right: 200px;
      bottom: -70px;
      height: 1px;
      background:#737882;
      z-index: 80;
    }
  }

  .slick-prev:before {
    content: "\f30a";
  }

  .slick-next:before {
    content: "\f30b";
  }  

  .slick-disabled:before {
    color:#ffffff !important;
  }

  .slick-prev {
    left:50%;
    bottom: -50px;
    top: auto;
    transform: translate(-100%, 150%);
    z-index: 90;
    background: #ffffff;
    padding-left: 80px;
    padding-right: 30px;
  }


  .slick-next {
    right:50%;
    bottom: -50px;
    top: auto;
    transform: translate(100%, 150%);
    z-index: 90;
    background: #ffffff;
    padding-right: 80px;
    padding-left: 30px;
  }

  .slick-prev:before, .slick-next:before {
    color:#9b9b9b;
    opacity: 1;
    font-family: "Font Awesome 5 Pro";
  }
}

@media (min-width: 992px) {
  #bloc-engagement {
    margin-top: 50px;

    h1 {
      margin-bottom: 30px;
      font-size: 15px;
      line-height: 28px;
      letter-spacing: 1px;
    }

    .slick-slide {
      height: 380px;
  
      .container-slides {
        margin: 0 15px;
        height: 100%;
  
        .row {
          height: 100%;
        }
      }
  
      & > div {
        height: 100%;
      }
  
      .picture {
        height: 100%;
  
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
  
      .text {
        //background: #ffffff;
        position: relative;
        padding: 110px 10px 0 10px;
        //margin-top: 10px;
        //margin-left: -40px;
        //margin-right: 10px;
  
        h2 {
          text-align: center;
          margin:0;
          color:$jauneMilan;
          font-size: 100px;
          font-weight: $bold;
          position: absolute;
          top:-7px;
          left:-40px;
          width: 80px;
        }
  
        p {
          letter-spacing: 1px;
        }
      }
    }
  }
}