#bloc-instagram {

  margin-top: 20px;
  margin-bottom: 20px;

  h1 {
    margin:0;
    margin-bottom: 20px;
    font-size: 17px;
    font-weight:$bold;
    text-transform: uppercase
  }

  .slideshow {
    .row > div {
      margin-bottom: 20px
    }
  }
}

@media (min-width: 992px) {
  #bloc-instagram {

    margin-top: 50px;
    margin-bottom: 40px;
  
    h1 {
      font-size: 30px;
      letter-spacing: 1px;
    }
  
    .slideshow {
      margin-bottom: 40px
    }

    .btn {
      letter-spacing: 1px;
    }
  }  
}