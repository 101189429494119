.btn:focus { 
  outline: none; 
  box-shadow: none;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Montserrat', sans-serif;
  color: #38373B;
}

/*
.App {
  //min-height: 100vh;
}
*/

.main {
  //min-height: calc(100% - 260px);
  padding-top: 90px;
}

.loading {
  text-align: center;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: $bold;
  font-size: 42px;
}

.btn-square {
  border-radius: 0;

  &:hover {
    text-decoration: none;
  }
}

.btn-milan {
  background: $jauneMilan;
  color: #fff;
  font-weight: $bold;
  font-size: 14px;
  padding: 10px 22px;

  &:hover {
    color: #000;
  }
}

.btn-milan-border {
  background: transparent;
  border: 3px solid $jauneMilan;
  color: #fff;
  font-weight: $bold;
  font-size: 14px;
  padding: 10px 22px;

  &:hover {
    color: $jauneMilan;
  }
}

.btn-3m-border {
  background: transparent;
  border: 3px solid $bleu3m;
  color: #fff;
  font-weight: $bold;
  font-size: 14px;
  padding: 10px 22px;

  &:hover {
    color: $bleu3m;
  }
}

.btn-black-border {
  background: transparent;
  border: 3px solid #000000;
  color: #000000;
  font-weight: $bold;
  font-size: 14px;
  padding: 10px 22px;

  &:hover {
    color: #000000;
  }
}

.btn-color-milan {
  color: $jauneMilan;
}

.btn-color-3m {
  color: $bleu3m;
}

.soon {
  margin-top: 90px;
  margin-bottom: 190px;
}

.bloc-type-1 {
  padding-top: 20px;
  padding-bottom: 20px;

  h1 {
    font-weight: $bold;
    font-size: 13px;
    text-transform: uppercase;
  }

  .chapo{
    padding-left: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: $medium;
    font-size: 10px;
    line-height: 18px;
    text-transform: uppercase;
    position:relative;
    text-align: justify;

    &:before {
      position: absolute;
      content: "";
      display:block;
      width: 2px;
      height: 30px;
      background: #000;
      left: 7px;
    }
  }
}

@media (min-width: 992px) {
  .bloc-type-1 {
    //padding-top: 64px;
    //padding-bottom: 108px;
  
    h1 {
      font-weight: $bold;
      font-size: 22px;
      letter-spacing: 1.22px;
      line-height: 30px;
    }
  
    .chapo{
      padding-left: 70px;
      margin-bottom: 30px;
      font-size: 14px;
      line-height: 28px;
      letter-spacing: 1px;
  
      &:before {
        height: 50px;
        left: 35px;
      }
    }
  }  
}
