footer {
  background: #fff;
  font-weight: $medium;
  font-size: 12px;
  text-align: center;

  .left, .center, .right {
    margin: 10px 0;
  }

  img {
    margin-right: 10px;
  }

  .nav-footer {
    color: inherit;
    text-decoration: none;

    &:after {
      content: '|';
      padding-left: 15px;
      padding-right: 15px;
    }

    &:last-of-type {
      margin-right: 0;

      &:after {
        display:none;
      }
    }
  }
}

@media (min-width: 992px) {
  footer {
    height: 80px;
    box-shadow: 0px 2px 4px rgba(127,157,157,.5);
    letter-spacing: 0.86px;
    line-height: 28px;

    text-align: left;

    .left, .center, .right {
      line-height: 80px;
      margin: 0;
    }

    .center {
      text-align: center;
    }
  
    .right {
      text-align: right;
    }
  
  }  
}