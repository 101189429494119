#bloc-ambition {
  .slick-ambition {

    padding-bottom: 50px;


    .slick-slide {
      h2 {
        color: $orangeMilan;
        font-size: 14px;
        font-weight: $bold;
        letter-spacing: 1px;
        line-height: 30px;
        text-transform: uppercase
      }

      p {
        color: #38373B;
        font-size: 12px;
        letter-spacing: 0.86px;
        line-height: 30px;
      }      

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        max-height: 200px;
      }
    }

    .slick-prev:before {
      content: "\f053";
    }
  
    .slick-next:before {
      content: "\f054";
    }  
  
    .slick-prev {
      left:50%;
      bottom: 0;
      top: auto;
      z-index: 90;
      background: #ffffff;
      transform: translate(-50px, 0px);
      padding: 10px;
      width: 50px;
      height: 50px;
    }
  
  
    .slick-next {
      left:50%;
      bottom: 0;
      top: auto;
      right: auto;
      z-index: 90;
      background: #ffffff;
      transform: translate(0px, 0px);
      padding: 10px;
      width: 50px;
      height: 50px;
    }
  
    .slick-prev:before, .slick-next:before {
      color:#4A4A4A;
      opacity: 1;
      font-family: "Font Awesome 5 Pro";
      font-size: 30px;
    }    
  }
}

@media (min-width: 992px) {
  #bloc-ambition {
    .slick-ambition {

      padding-bottom: 0px;

      .slick-slide {

        .row {
          height: 330px;
        }

        img {
          max-height: 330px;
        }
      }

      .slick-prev {
        transform: translate(-115px, 0px);
      }
    
    
      .slick-next {
        transform: translate(-65px, 0px);
      }      
    }
  }
}
