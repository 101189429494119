#page-faq {
  .intro {
    height: 400px;
    background-color: #ECC81A;
    background-repeat: no-repeat;
    background-position: top -190px center;
    margin-bottom: 90px;

    h1 {
      font-size:25px;
      font-weight: $bold;
      margin:0;
      letter-spacing: 1.39px;
      line-height: 40px;
      text-transform: uppercase;
      color:#ffffff;
      letter-spacing: 1px;
    }

    h2 {
      font-size:18px;
      font-weight: $semiBold;
      margin:0;
      line-height: 40px;
      margin-bottom: 40px;
      letter-spacing: 1px;
    }
  }

  h3 {
    font-size:20px;
    font-weight: $bold;
    margin:0;
    line-height: 40px;
    letter-spacing: 1.11px;
    border-top: 5px solid #000;
    padding-left: 5px;
  }

  .questions {
    margin-bottom: 90px;
  }

  .question {
    border-bottom: 2px solid #000;

    &:first-of-type {
      border-top: 2px solid #000;
    }

    .btn {
      position:relative;
      color:#38373B;
      font-size:16px;
      font-weight: $semiBold;
      margin:0;
      padding: 20px 0;
      line-height: 40px;
      letter-spacing: 0.89px;
      border: 0;
      display: block;
      width: 100%;
      border-radius: 0;
      text-align: left;
      padding-right: 30px;

      &:after {
        content: '';
        width: 18px;
        height: 18px;
        position: absolute;
        right: 0;
        top: 31px;
        background:url('../assets/img/plus.svg') center center no-repeat;
        transition: transform 300ms ease-in-out;
        transform: rotate(90deg + 45deg)
      }

      &.collapsed {
        &:after {
          transform: rotate(0deg)
        }
      }

      &:hover {
        text-decoration: none;
      }
    }

    .reponse {
      font-size:12px;
      font-weight: $regular;
      line-height: 30px;
      letter-spacing: 0.6px;
    }
  }
  /*
  margin-top: -100px;
  height: 640px;
  padding-top:170px;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;

  .container {
    text-align: center;
    color:#fff;

    h1, h2 {
      text-transform: uppercase
    }

    h1 {
      margin-top: 40px;
      margin-bottom: 20px;
      font-size: 35px;
      font-weight:$extraBold;
    }

    h2 {
      margin: 0;
      font-size: 25px;
      font-weight:$bold;
      line-height: 40px;
    }

    .counter {
      margin-top: 40px;
      text-transform: uppercase;
      color: $jauneMilan;
      border: 4px solid $jauneMilan;
      font-size: 30px;
      font-weight:$extraBold;
      width: 260px;
      height: 70px;
      line-height: 64px;
      margin: 40px auto;
    }
  }
  */
}