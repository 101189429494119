#bloc-sala {
  padding-top: 20px;
  padding-bottom: 20px;

  h2 {
    color :$bleu3m;
    font-weight: $bold;
    font-size: 13px;
    text-transform: uppercase;
  }

  .quote {
    font-weight: $regular;
    font-size: 12px;
    line-height: 20px;
    text-align: justify;
  }
  
  .presentation {
    padding: 20px;
  }

  em {
    color :$bleu3m;
    font-weight: $semiBold;
    font-size: 12px;
    line-height: 25px;
    font-style: italic;
    letter-spacing: 0.86px;
  }
}

@media (min-width: 992px) {
  #bloc-sala {
    padding-top: 32px;
    padding-bottom: 108px;

    h2 {
      font-size: 24px;
      line-height: 35px;
      letter-spacing: 1.34px;
    }
  
    .quote {
      line-height: 30px;
      letter-spacing: 1px;
    }

    .presentation {
      display: flex;
      flex-direction: column;
      justify-content: start;
      height: 100%;
      margin: 0;
      padding: 0;
      padding-right: 20px;
      padding-top:55px;
      position:relative;

      &:before {
        content: '';
        position:absolute;
        top:0;
        left:0;
        width: 83px;
        height: 52px;
        background:url('../assets/img/guillemets.svg');
      }
    }
  }  
}