#bloc-mufpp {
  position: relative;
  background-position: center top;
  padding-top: 80px;
  padding-bottom: 80px;

  .mufpp {
    background: $jauneMilan;
    position: relative;
    padding: 15px;

    &:before, &:after {
      content: '';
      position:absolute;
      width: 56px;
      height: 35px;
      background:url('../assets/img/guillemets-noires.svg') left top no-repeat;
    }

    &:before {
      top:-20px;
      left:-6px;
    }

    &:after {
      bottom:35px;
      right:-6px;
    }

    h1 {
      margin:0;
      margin-bottom: 7px;
      font-size: 12px;
      font-weight:$semiBold;
      letter-spacing: 0,86px;
      line-height: 20px;
      text-transform: uppercase;
      text-align: center;
    }

    p {
      margin:0;
      font-size: 12px;
      font-weight:$bold;
      line-height: 20px;
      text-transform: uppercase;
      text-align: justify;
      margin-bottom: 30px;
    }

    a {
      color:#000000;
      margin:0;
      font-size: 14px;
      font-weight:$bold;
      line-height: 28px;
      text-transform: uppercase;
      text-decoration: none;
    }

  }
}

@media (min-width: 992px) {
  #bloc-mufpp {
    background-position: center right;
    padding-top: 0px;
    padding-bottom: 0px;
    
    &:before {
      content: '';
      background: $jauneMilan;
      width: 50%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    .mufpp {
      padding-top: 85px;
      padding-bottom: 45px;
      padding-left: 22px;
      padding-right: 76px;

      &:before {
        top:45px;
        left:0;
      }

      &:after {
        bottom:45px;
        right:48px;
      }   
      
      h1 {
        text-align: left;
        letter-spacing: 1px;
      }

      p {
        font-size: 14px;
        line-height: 28px;
        letter-spacing: 1px;
      }

      a {
        letter-spacing: 1px;
      }
    }
  
  }
}