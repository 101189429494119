@import url(https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i);
@charset "UTF-8";
@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 940px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: table; }

.modal-video-inner {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%; }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -35px;
  right: -35px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

.btn:focus {
  outline: none;
  box-shadow: none; }

html {
  scroll-behavior: smooth; }

body {
  font-family: 'Montserrat', sans-serif;
  color: #38373B; }

/*
.App {
  //min-height: 100vh;
}
*/
.main {
  padding-top: 90px; }

.loading {
  text-align: center;
  min-height: 200px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  font-weight: 700;
  font-size: 42px; }

.btn-square {
  border-radius: 0; }
  .btn-square:hover {
    text-decoration: none; }

.btn-milan {
  background: #f0c674;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  padding: 10px 22px; }
  .btn-milan:hover {
    color: #000; }

.btn-milan-border {
  background: transparent;
  border: 3px solid #f0c674;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  padding: 10px 22px; }
  .btn-milan-border:hover {
    color: #f0c674; }

.btn-3m-border {
  background: transparent;
  border: 3px solid #174395;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  padding: 10px 22px; }
  .btn-3m-border:hover {
    color: #174395; }

.btn-black-border {
  background: transparent;
  border: 3px solid #000000;
  color: #000000;
  font-weight: 700;
  font-size: 14px;
  padding: 10px 22px; }
  .btn-black-border:hover {
    color: #000000; }

.btn-color-milan {
  color: #f0c674; }

.btn-color-3m {
  color: #174395; }

.soon {
  margin-top: 90px;
  margin-bottom: 190px; }

.bloc-type-1 {
  padding-top: 20px;
  padding-bottom: 20px; }
  .bloc-type-1 h1 {
    font-weight: 700;
    font-size: 13px;
    text-transform: uppercase; }
  .bloc-type-1 .chapo {
    padding-left: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 10px;
    line-height: 18px;
    text-transform: uppercase;
    position: relative;
    text-align: justify; }
    .bloc-type-1 .chapo:before {
      position: absolute;
      content: "";
      display: block;
      width: 2px;
      height: 30px;
      background: #000;
      left: 7px; }

@media (min-width: 992px) {
  .bloc-type-1 h1 {
    font-weight: 700;
    font-size: 22px;
    letter-spacing: 1.22px;
    line-height: 30px; }
  .bloc-type-1 .chapo {
    padding-left: 70px;
    margin-bottom: 30px;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1px; }
    .bloc-type-1 .chapo:before {
      height: 50px;
      left: 35px; } }

header {
  position: fixed;
  left: 0;
  right: 0;
  color: #fff;
  transition: background 600ms ease-in-out;
  padding-bottom: 10px;
  min-height: 90px;
  z-index: 2018; }
  header .navbar {
    padding: 0;
    text-align: center; }
  header .navbar-brand {
    padding: 0; }
    header .navbar-brand img {
      max-width: 200px; }
  header .navbar-collapse {
    padding-top: 0px; }
  header .navbar-light .navbar-nav .nav-link.nav-main.active {
    font-weight: 700; }
  header .navbar-light .navbar-nav .nav-item .nav-link {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1.16px;
    color: #fff;
    position: relative; }
  header .navbar-light .navbar-nav .nav-item.dropdown .nav-link {
    font-size: 16px;
    font-weight: 700; }
  header .navbar-secondary {
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center; }
    header .navbar-secondary .nav-item {
      margin: 0 10px; }
    header .navbar-secondary .dropdown-menu {
      min-width: auto; }
      header .navbar-secondary .dropdown-menu .dropdown-item {
        padding: .25em 1rem; }
      header .navbar-secondary .dropdown-menu .dropdown-toggle::after {
        position: absolute;
        left: 50%;
        bottom: 0px;
        margin: 0;
        margin-left: -6px; }
  header.opaque {
    background: #fff;
    box-shadow: 0px 2px 4px rgba(127, 157, 157, 0.5); }
    header.opaque .navbar-brand img {
      box-shadow: 0px 0px 10px -2px #aaa;
      border-radius: 0 0 4px 4px; }
    header.opaque .navbar-light .navbar-nav .nav-link {
      color: #000; }
    header.opaque .navbar-primary .nav-cta {
      border-color: #000000; }
      header.opaque .navbar-primary .nav-cta:hover {
        border-color: #739155; }
        header.opaque .navbar-primary .nav-cta:hover .nav-main {
          color: #739155 !important; }

@media (min-width: 992px) {
  header .navbar-collapse {
    padding-top: 0px; }
  header .navbar-brand {
    margin-right: 0; }
    header .navbar-brand img {
      max-width: none; }
  header .navbar-light .navbar-nav .nav-link.nav-main:after {
    content: '';
    position: absolute;
    left: 70px;
    right: 70px;
    bottom: 0;
    height: 3px;
    background: #000000;
    transition: 250ms left ease-in-out, 250ms right ease-in-out; }
  header .navbar-light .navbar-nav .nav-link.nav-main.active:after, header .navbar-light .navbar-nav .nav-link.nav-main:hover:after {
    left: 17px;
    right: 17px; }
  header .navbar-light .navbar-nav .nav-link:hover {
    color: #000; }
  header .navbar-primary .nav-item {
    margin: 0 11px; }
  header .navbar-primary .nav-cta {
    border: 1px solid #ffffff;
    border-radius: 12px; }
    header .navbar-primary .nav-cta:hover {
      border-color: #000000; }
    header .navbar-primary .nav-cta .nav-main {
      font-weight: 600 !important; }
      header .navbar-primary .nav-cta .nav-main:after {
        display: none !important; }
  header .navbar-secondary .nav-item {
    margin: 0; }
  header .navbar-secondary.navbar-nav .nav-item.dropdown .nav-link {
    padding-top: 10px; }
  header .navbar-secondary.navbar-nav .nav-item .nav-link {
    font-size: 20px; } }

footer {
  background: #fff;
  font-weight: 500;
  font-size: 12px;
  text-align: center; }
  footer .left, footer .center, footer .right {
    margin: 10px 0; }
  footer img {
    margin-right: 10px; }
  footer .nav-footer {
    color: inherit;
    text-decoration: none; }
    footer .nav-footer:after {
      content: '|';
      padding-left: 15px;
      padding-right: 15px; }
    footer .nav-footer:last-of-type {
      margin-right: 0; }
      footer .nav-footer:last-of-type:after {
        display: none; }

@media (min-width: 992px) {
  footer {
    height: 80px;
    box-shadow: 0px 2px 4px rgba(127, 157, 157, 0.5);
    letter-spacing: 0.86px;
    line-height: 28px;
    text-align: left; }
    footer .left, footer .center, footer .right {
      line-height: 80px;
      margin: 0; }
    footer .center {
      text-align: center; }
    footer .right {
      text-align: right; } }

#bloc-abientot {
  height: 450px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center; }
  #bloc-abientot h1 {
    font-weight: 700;
    font-size: 30px;
    text-transform: uppercase;
    color: #ffffff;
    margin-top: 30px;
    letter-spacing: 1px; }
  #bloc-abientot h2 {
    font-weight: 500;
    font-size: 22px;
    text-transform: uppercase;
    color: #ffffff;
    margin-top: 30px;
    letter-spacing: 1px; }
  #bloc-abientot .navbar-nav {
    margin-top: 10px;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center; }
    #bloc-abientot .navbar-nav .nav-item {
      margin-left: 12px;
      margin-right: 12px; }
      #bloc-abientot .navbar-nav .nav-item a {
        color: #ffffff; }

.marker {
  border-radius: 50%;
  border: 5px solid #739155;
  width: 16px;
  height: 16px;
  margin-top: -16px;
  box-shadow: 0px 14px 24px 1px rgba(0, 0, 0, 0.3); }
  .marker:after {
    position: absolute;
    content: '';
    width: 0px;
    height: 0px;
    top: -5px;
    left: 0px;
    border: 8px solid transparent;
    border-top: 16px solid #739155; }

#bloc-contact {
  background: #F8F8F8;
  padding-bottom: 50px;
  padding-top: 50px;
  position: relative;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.86px;
  line-height: 28px;
  /* Set the size of the div element that contains the map */ }
  #bloc-contact h2 {
    color: #174395;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 1.22px;
    line-height: 40px; }
  #bloc-contact p {
    letter-spacing: 1px; }
  #bloc-contact .col:first-child {
    margin-bottom: 50px; }
  #bloc-contact #map {
    height: 400px;
    /* The height is 400 pixels */
    width: 100%;
    /* The width is the width of the web page */ }
  #bloc-contact .form {
    max-height: 800px;
    overflow: hidden; }
  #bloc-contact .form-loading {
    max-height: 0; }
  #bloc-contact .error {
    -webkit-order: -1;
            order: -1;
    color: #e6895c; }
  #bloc-contact .field-textarea .error {
    -webkit-order: initial;
            order: initial; }
  #bloc-contact .Modal {
    /*
    position: absolute;
    top: 40px;
    left: 40px;
    right: 40px;
    bottom: 40px;
    */
    margin: 100px auto;
    max-width: 900px;
    background: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.2) 0 0 20px -10px;
    border-radius: 5px; }
  #bloc-contact .Overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 1001; }
  #bloc-contact .thank {
    padding-top: 40px;
    padding-bottom: 40px;
    min-height: 200px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
    color: #174395;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 28px; }
    #bloc-contact .thank .fal {
      font-size: 80px; }
    #bloc-contact .thank .btn-link {
      text-decoration: underline;
      color: #174395; }
  #bloc-contact .loading {
    color: #174395; }

/**
* Make the field a flex-container, reverse the order so label is on top.
*/
.field {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column-reverse;
          flex-flow: column-reverse;
  margin-bottom: 1em; }

.field-textarea, .field-radio {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
          flex-flow: column;
  margin-bottom: 1em; }

/**
* Add a transition to the label and input.
* I'm not even sure that touch-action: manipulation works on
* inputs, but hey, it's new and cool and could remove the
* pesky delay.
*/
label, input, select, textarea {
  transition: all 0.2s;
  touch-action: manipulation; }

input, select, textarea {
  border: 0;
  border-bottom: 1px solid #ccc;
  font-family: inherit;
  -webkit-appearance: none;
  border-radius: 0;
  padding: 0;
  cursor: text;
  background: #F8F8F8; }

.field-checkbox input {
  width: 15px;
  height: 15px;
  background-color: #FFFFFF;
  border: 1px solid #D8D8D8;
  margin-right: 8px;
  cursor: pointer; }
  .field-checkbox input:checked {
    background: #38373B !important;
    box-shadow: inset #ffffff 0px 0px 0px 2px; }
  .field-checkbox input:focus {
    border-bottom: 1px solid #ccc; }

.field-radio input {
  width: 15px;
  height: 15px;
  background-color: #FFFFFF;
  border: 1px solid #D8D8D8;
  margin-right: 8px;
  border-radius: 15px;
  cursor: pointer; }
  .field-radio input:checked {
    background: #38373B !important;
    box-shadow: inset #ffffff 0px 0px 0px 2px; }
  .field-radio input:focus {
    border-bottom: 1px solid #ccc; }

select {
  font-weight: 500;
  padding-left: 5px; }

input:focus, select:focus {
  outline: 0;
  border-bottom: 1px solid #666; }

.form-uppercase label {
  text-transform: uppercase;
  letter-spacing: 1px; }

/**
* Translate down and scale the label up to cover the placeholder,
* when following an input (with placeholder-shown support).
* Also make sure the label is only on one row, at max 2/3rds of the
* field—to make sure it scales properly and doesn't wrap.
*/
input:placeholder-shown + label {
  cursor: text;
  max-width: 66.66%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-transform-origin: left bottom;
          transform-origin: left bottom;
  -webkit-transform: translate(0, 2.125rem);
          transform: translate(0, 2.125rem);
  margin-left: 5px; }

/**
* By default, the placeholder should be transparent. Also, it should
* inherit the transition.
*/
::-webkit-input-placeholder {
  opacity: 0;
  transition: inherit; }

/**
* Show the placeholder when the input is focused.
*/
input:focus::-webkit-input-placeholder {
  opacity: 1; }

/**
* When the element is focused, remove the label transform.
* Also, do this when the placeholder is _not_ shown, i.e. when
* there's something in the input at all.
*/
input:not(:placeholder-shown) + label,
input:focus + label {
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
  cursor: pointer; }

.leaflet-container {
  height: 400px; }

@media (min-width: 992px) {
  .leaflet-container {
    width: 80%;
    margin: 0 auto; }
  #bloc-contact .col:first-child {
    margin-bottom: 0; } }

.has-error input, .has-error select, .has-error textarea {
  border-bottom: 1px solid #e6895c; }

.has-error select, .has-error label {
  color: #e6895c; }

#bloc-engagement {
  margin-top: 30px;
  padding-bottom: 130px; }
  #bloc-engagement h1 {
    text-align: center;
    margin: 0;
    margin-bottom: 30px;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.71px;
    line-height: 22px; }
  #bloc-engagement h3 {
    background: #5f9ea0;
    color: #fff;
    font-size: 36px;
    line-height: 100px;
    margin: 10px;
    padding: 2%;
    position: relative;
    text-align: center; }
  #bloc-engagement .slick-slide .picture img {
    width: 100%; }
  #bloc-engagement .slick-slide .text h2 {
    margin: 0;
    color: #f0c674;
    font-size: 100px;
    font-weight: 700;
    margin-top: -60px; }
  #bloc-engagement .slick-slide .text p {
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    line-height: 25px; }
  #bloc-engagement .slick-engagements {
    position: relative; }
    #bloc-engagement .slick-engagements:after {
      position: absolute;
      content: '';
      left: 200px;
      right: 200px;
      bottom: -70px;
      height: 1px;
      background: #737882;
      z-index: 80; }
  #bloc-engagement .slick-prev:before {
    content: "\F30A"; }
  #bloc-engagement .slick-next:before {
    content: "\F30B"; }
  #bloc-engagement .slick-disabled:before {
    color: #ffffff !important; }
  #bloc-engagement .slick-prev {
    left: 50%;
    bottom: -50px;
    top: auto;
    -webkit-transform: translate(-100%, 150%);
            transform: translate(-100%, 150%);
    z-index: 90;
    background: #ffffff;
    padding-left: 80px;
    padding-right: 30px; }
  #bloc-engagement .slick-next {
    right: 50%;
    bottom: -50px;
    top: auto;
    -webkit-transform: translate(100%, 150%);
            transform: translate(100%, 150%);
    z-index: 90;
    background: #ffffff;
    padding-right: 80px;
    padding-left: 30px; }
  #bloc-engagement .slick-prev:before, #bloc-engagement .slick-next:before {
    color: #9b9b9b;
    opacity: 1;
    font-family: "Font Awesome 5 Pro"; }

@media (min-width: 992px) {
  #bloc-engagement {
    margin-top: 50px; }
    #bloc-engagement h1 {
      margin-bottom: 30px;
      font-size: 15px;
      line-height: 28px;
      letter-spacing: 1px; }
    #bloc-engagement .slick-slide {
      height: 380px; }
      #bloc-engagement .slick-slide .container-slides {
        margin: 0 15px;
        height: 100%; }
        #bloc-engagement .slick-slide .container-slides .row {
          height: 100%; }
      #bloc-engagement .slick-slide > div {
        height: 100%; }
      #bloc-engagement .slick-slide .picture {
        height: 100%; }
        #bloc-engagement .slick-slide .picture img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
      #bloc-engagement .slick-slide .text {
        position: relative;
        padding: 110px 10px 0 10px; }
        #bloc-engagement .slick-slide .text h2 {
          text-align: center;
          margin: 0;
          color: #f0c674;
          font-size: 100px;
          font-weight: 700;
          position: absolute;
          top: -7px;
          left: -40px;
          width: 80px; }
        #bloc-engagement .slick-slide .text p {
          letter-spacing: 1px; } }

#bloc-header-pacte {
  height: 557px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover; }
  #bloc-header-pacte .container {
    height: 100%;
    text-align: center;
    color: #fff;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center; }
    #bloc-header-pacte .container h1, #bloc-header-pacte .container h2 {
      text-transform: uppercase; }
    #bloc-header-pacte .container h1 {
      margin: 0;
      font-size: 18px;
      font-weight: 800;
      letter-spacing: 2,08px;
      line-height: 27px; }
    #bloc-header-pacte .container h2 {
      margin: 0;
      margin-top: 40px;
      font-size: 13px;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 1.17px; }

@media (min-width: 992px) {
  #bloc-header-pacte .container h1 {
    font-size: 25px;
    line-height: 36px;
    letter-spacing: 1px; }
  #bloc-header-pacte .container h2 {
    font-size: 14px;
    line-height: 28px;
    margin-top: 20px;
    margin-left: 150px;
    margin-right: 150px; } }

#bloc-instagram {
  margin-top: 20px;
  margin-bottom: 20px; }
  #bloc-instagram h1 {
    margin: 0;
    margin-bottom: 20px;
    font-size: 17px;
    font-weight: 700;
    text-transform: uppercase; }
  #bloc-instagram .slideshow .row > div {
    margin-bottom: 20px; }

@media (min-width: 992px) {
  #bloc-instagram {
    margin-top: 50px;
    margin-bottom: 40px; }
    #bloc-instagram h1 {
      font-size: 30px;
      letter-spacing: 1px; }
    #bloc-instagram .slideshow {
      margin-bottom: 40px; }
    #bloc-instagram .btn {
      letter-spacing: 1px; } }

#bloc-mufpp {
  position: relative;
  background-position: center top;
  padding-top: 80px;
  padding-bottom: 80px; }
  #bloc-mufpp .mufpp {
    background: #f0c674;
    position: relative;
    padding: 15px; }
    #bloc-mufpp .mufpp:before, #bloc-mufpp .mufpp:after {
      content: '';
      position: absolute;
      width: 56px;
      height: 35px;
      background: url(/static/media/guillemets-noires.6f8ae5f3.svg) left top no-repeat; }
    #bloc-mufpp .mufpp:before {
      top: -20px;
      left: -6px; }
    #bloc-mufpp .mufpp:after {
      bottom: 35px;
      right: -6px; }
    #bloc-mufpp .mufpp h1 {
      margin: 0;
      margin-bottom: 7px;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0,86px;
      line-height: 20px;
      text-transform: uppercase;
      text-align: center; }
    #bloc-mufpp .mufpp p {
      margin: 0;
      font-size: 12px;
      font-weight: 700;
      line-height: 20px;
      text-transform: uppercase;
      text-align: justify;
      margin-bottom: 30px; }
    #bloc-mufpp .mufpp a {
      color: #000000;
      margin: 0;
      font-size: 14px;
      font-weight: 700;
      line-height: 28px;
      text-transform: uppercase;
      text-decoration: none; }

@media (min-width: 992px) {
  #bloc-mufpp {
    background-position: center right;
    padding-top: 0px;
    padding-bottom: 0px; }
    #bloc-mufpp:before {
      content: '';
      background: #f0c674;
      width: 50%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0; }
    #bloc-mufpp .mufpp {
      padding-top: 85px;
      padding-bottom: 45px;
      padding-left: 22px;
      padding-right: 76px; }
      #bloc-mufpp .mufpp:before {
        top: 45px;
        left: 0; }
      #bloc-mufpp .mufpp:after {
        bottom: 45px;
        right: 48px; }
      #bloc-mufpp .mufpp h1 {
        text-align: left;
        letter-spacing: 1px; }
      #bloc-mufpp .mufpp p {
        font-size: 14px;
        line-height: 28px;
        letter-spacing: 1px; }
      #bloc-mufpp .mufpp a  {
        letter-spacing: 1px; } }

#bloc-raisons .slick-slider .slide h2 {
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #e6895c;
  text-transform: uppercase; }

#bloc-raisons .slick-slider .slide p {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-align: justify; }

#bloc-raisons .slick-slider .slick-dots {
  position: static;
  bottom: auto;
  margin-top: 10px; }
  #bloc-raisons .slick-slider .slick-dots ul {
    padding: 0; }
  #bloc-raisons .slick-slider .slick-dots li {
    width: auto;
    height: auto;
    margin: 0 10px; }
  #bloc-raisons .slick-slider .slick-dots .slick-active .button {
    color: #e6895c; }
  #bloc-raisons .slick-slider .slick-dots .button {
    font-weight: 800;
    font-size: 24px;
    letter-spacing: 1.67px;
    line-height: 30px;
    text-decoration: none;
    color: #CECECE; }

@media (min-width: 992px) {
  #bloc-raisons {
    padding-top: 80px;
    padding-bottom: 80px; }
    #bloc-raisons .slick-slider .slide h2 {
      font-weight: 700;
      font-size: 14px;
      line-height: 30px;
      color: #e6895c;
      text-transform: uppercase;
      letter-spacing: 1px; }
    #bloc-raisons .slick-slider .slide p {
      font-weight: 400;
      font-size: 12px;
      line-height: 30px;
      letter-spacing: 1px; }
    #bloc-raisons .slick-slider .slick-dots {
      position: static;
      bottom: auto;
      margin-top: -44px; }
      #bloc-raisons .slick-slider .slick-dots ul {
        padding: 0; }
      #bloc-raisons .slick-slider .slick-dots li {
        width: auto;
        height: auto;
        margin: 0 10px; }
      #bloc-raisons .slick-slider .slick-dots .slick-active .button {
        color: #e6895c; }
      #bloc-raisons .slick-slider .slick-dots .button {
        font-weight: 800;
        font-size: 50px;
        letter-spacing: 2px;
        line-height: 30px;
        text-decoration: none;
        color: #CECECE; } }

#bloc-saurel {
  padding-top: 20px;
  padding-bottom: 20px; }
  #bloc-saurel .container {
    background-position: top center;
    background-repeat: no-repeat; }
  #bloc-saurel h2 {
    color: #739155;
    font-weight: 700;
    font-size: 13px;
    text-transform: uppercase; }
  #bloc-saurel .quote {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-align: justify; }
  #bloc-saurel .presentation {
    background: #ffffff;
    margin-top: 250px;
    padding: 20px; }
  #bloc-saurel em {
    color: #739155;
    font-weight: 600;
    font-size: 12px;
    line-height: 25px;
    font-style: italic;
    letter-spacing: 0.86px; }

@media (min-width: 992px) {
  #bloc-saurel {
    padding-top: 64px;
    padding-bottom: 0; }
    #bloc-saurel .container {
      background-position: center right; }
    #bloc-saurel h2 {
      font-size: 24px;
      line-height: 35px;
      letter-spacing: 1.34px;
      padding-right: 120px; }
    #bloc-saurel .quote {
      line-height: 30px;
      letter-spacing: 1px; }
    #bloc-saurel .presentation {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-justify-content: center;
              justify-content: center;
      height: 100%;
      margin: 0;
      padding: 0;
      padding-right: 20px;
      padding-top: 55px;
      position: relative;
      background: linear-gradient(to right, white 0%, white 50%, rgba(255, 255, 255, 0.6) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#99ffffff',GradientType=1 ); }
      #bloc-saurel .presentation:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 83px;
        height: 52px;
        background: url(/static/media/guillemets.bc7688b2.svg); } }

#bloc-sala {
  padding-top: 20px;
  padding-bottom: 20px; }
  #bloc-sala h2 {
    color: #174395;
    font-weight: 700;
    font-size: 13px;
    text-transform: uppercase; }
  #bloc-sala .quote {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-align: justify; }
  #bloc-sala .presentation {
    padding: 20px; }
  #bloc-sala em {
    color: #174395;
    font-weight: 600;
    font-size: 12px;
    line-height: 25px;
    font-style: italic;
    letter-spacing: 0.86px; }

@media (min-width: 992px) {
  #bloc-sala {
    padding-top: 32px;
    padding-bottom: 108px; }
    #bloc-sala h2 {
      font-size: 24px;
      line-height: 35px;
      letter-spacing: 1.34px; }
    #bloc-sala .quote {
      line-height: 30px;
      letter-spacing: 1px; }
    #bloc-sala .presentation {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-justify-content: start;
              justify-content: start;
      height: 100%;
      margin: 0;
      padding: 0;
      padding-right: 20px;
      padding-top: 55px;
      position: relative; }
      #bloc-sala .presentation:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 83px;
        height: 52px;
        background: url(/static/media/guillemets.bc7688b2.svg); } }

#bloc-venir {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  color: #ffffff; }
  #bloc-venir a {
    margin-top: 20px;
    color: #ffffff;
    text-decoration: underline;
    font-weight: 700;
    font-size: 19px; }
  #bloc-venir .office-tourisme {
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 14px; }

@media (min-width: 992px) {
  #bloc-venir {
    height: 640px; }
    #bloc-venir a {
      font-size: 30px;
      line-height: 50px;
      letter-spacing: 1.67px;
      margin-top: auto;
      margin-bottom: auto; }
    #bloc-venir .office-tourisme {
      margin-top: -120px;
      letter-spacing: 1.79px;
      line-height: 35px; } }

#bloc-video {
  height: 300px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center; }
  #bloc-video h1 {
    font-weight: 700;
    font-size: 19px;
    text-transform: uppercase;
    color: #ffffff;
    margin-top: 30px; }
  #bloc-video img {
    width: 80px; }

@media (min-width: 992px) {
  #bloc-video {
    height: 640px; }
    #bloc-video h1 {
      font-size: 30px;
      letter-spacing: 1px; }
    #bloc-video img {
      width: auto; } }

#bloc-vision {
  background: #F8F8F8; }
  #bloc-vision .vignette {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    margin-bottom: 15px;
    height: 350px;
    padding: 60px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
    color: #fff;
    position: relative; }
    #bloc-vision .vignette:after {
      content: '';
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(115, 145, 85, 0.3); }
    #bloc-vision .vignette .big {
      z-index: 20;
      font-size: 50px;
      font-weight: 700;
      letter-spacing: 5px;
      line-height: 28px;
      margin-bottom: 40px; }
    #bloc-vision .vignette .small {
      z-index: 20;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 1.25px;
      line-height: 30px; }
  #bloc-vision .row-2 .col-lg-6:first-child {
    -webkit-order: 2;
            order: 2; }

@media (min-width: 992px) {
  #bloc-vision .offset-image {
    margin-top: 124px; }
  #bloc-vision .row-2 {
    margin-top: -94px; }
    #bloc-vision .row-2 .btn {
      margin-bottom: 60px; }
  #bloc-vision .vignette {
    margin-bottom: 0; }
  #bloc-vision .row-2 .col-lg-6:first-child {
    -webkit-order: initial;
            order: initial; } }

#bloc-welcome {
  margin-top: -90px;
  padding-top: 100px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding-bottom: 20px; }
  #bloc-welcome .container {
    text-align: center;
    color: #fff; }
    #bloc-welcome .container h1, #bloc-welcome .container h2 {
      text-transform: uppercase; }
    #bloc-welcome .container h1 {
      margin: 0;
      margin-top: 40px;
      font-size: 13px;
      font-weight: 800; }
    #bloc-welcome .container h2 {
      margin: 0;
      margin-top: 20px;
      font-size: 18px;
      font-weight: 700;
      line-height: normal;
      line-height: initial; }
    #bloc-welcome .container h3 {
      margin: 0;
      margin-top: 20px;
      font-size: 18px;
      font-weight: 600;
      line-height: 40px;
      letter-spacing: 1px; }
    #bloc-welcome .container .counter {
      margin-top: 40px;
      text-transform: uppercase;
      color: #f0c674;
      border: 4px solid #f0c674;
      font-size: 30px;
      font-weight: 800;
      width: 260px;
      height: 70px;
      line-height: 64px;
      margin: 40px auto;
      letter-spacing: 2px; }

@media (min-width: 992px) {
  #bloc-welcome {
    margin-top: -100px;
    height: 640px;
    padding-top: 130px; }
    #bloc-welcome .container h1 {
      font-size: 20px;
      letter-spacing: 1px; }
    #bloc-welcome .container h2 {
      font-size: 35px;
      line-height: 40px;
      letter-spacing: 1px;
      margin-left: 90px;
      margin-right: 90px; } }

#bloc-ambition .slick-ambition {
  padding-bottom: 50px; }
  #bloc-ambition .slick-ambition .slick-slide h2 {
    color: #e6895c;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 30px;
    text-transform: uppercase; }
  #bloc-ambition .slick-ambition .slick-slide p {
    color: #38373B;
    font-size: 12px;
    letter-spacing: 0.86px;
    line-height: 30px; }
  #bloc-ambition .slick-ambition .slick-slide img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    max-height: 200px; }
  #bloc-ambition .slick-ambition .slick-prev:before {
    content: "\F053"; }
  #bloc-ambition .slick-ambition .slick-next:before {
    content: "\F054"; }
  #bloc-ambition .slick-ambition .slick-prev {
    left: 50%;
    bottom: 0;
    top: auto;
    z-index: 90;
    background: #ffffff;
    -webkit-transform: translate(-50px, 0px);
            transform: translate(-50px, 0px);
    padding: 10px;
    width: 50px;
    height: 50px; }
  #bloc-ambition .slick-ambition .slick-next {
    left: 50%;
    bottom: 0;
    top: auto;
    right: auto;
    z-index: 90;
    background: #ffffff;
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
    padding: 10px;
    width: 50px;
    height: 50px; }
  #bloc-ambition .slick-ambition .slick-prev:before, #bloc-ambition .slick-ambition .slick-next:before {
    color: #4A4A4A;
    opacity: 1;
    font-family: "Font Awesome 5 Pro";
    font-size: 30px; }

@media (min-width: 992px) {
  #bloc-ambition .slick-ambition {
    padding-bottom: 0px; }
    #bloc-ambition .slick-ambition .slick-slide .row {
      height: 330px; }
    #bloc-ambition .slick-ambition .slick-slide img {
      max-height: 330px; }
    #bloc-ambition .slick-ambition .slick-prev {
      -webkit-transform: translate(-115px, 0px);
              transform: translate(-115px, 0px); }
    #bloc-ambition .slick-ambition .slick-next {
      -webkit-transform: translate(-65px, 0px);
              transform: translate(-65px, 0px); } }

#bloc-objectifs {
  padding-top: 20px;
  padding-bottom: 20px;
  text-transform: uppercase;
  color: #000000;
  font-size: 13px;
  letter-spacing: 1px;
  line-height: 23px; }
  #bloc-objectifs h1 {
    color: #000000;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 23px; }
  #bloc-objectifs .presentation {
    border-left: 11px solid #f0c674;
    background: #ffffff;
    padding: 8px 14px; }

@media (min-width: 992px) {
  #bloc-objectifs {
    padding-top: 80px;
    padding-bottom: 70px;
    text-align: justify;
    font-size: 16px;
    line-height: 30px; }
    #bloc-objectifs h1 {
      font-size: 16px;
      line-height: 30px; }
    #bloc-objectifs .presentation {
      border-left: 21px solid #f0c674;
      padding: 16px 32px; } }

#bloc-mapmonde {
  padding: 20px 0;
  border-top: 2px solid #f0c674;
  border-bottom: 2px solid #f0c674; }
  #bloc-mapmonde .row {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover; }
  #bloc-mapmonde p {
    margin: 0; }
  #bloc-mapmonde a {
    margin-top: 25px; }
  #bloc-mapmonde .cta {
    margin: 0 auto;
    background-color: rgba(240, 198, 116, 0.79);
    color: #000000;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 30px;
    text-align: center;
    padding: 20px; }

@media (min-width: 992px) {
  #bloc-mapmonde {
    padding: 20px 0;
    border-top: 2px solid #f0c674;
    border-bottom: 2px solid #f0c674; }
    #bloc-mapmonde .row {
      height: 560px;
      background-repeat: no-repeat;
      background-position: center center; }
    #bloc-mapmonde .col {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-justify-content: center;
              justify-content: center; }
    #bloc-mapmonde .cta {
      width: 535px;
      margin: 0 auto;
      background-color: rgba(240, 198, 116, 0.79);
      color: #000000;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 1px;
      line-height: 30px;
      text-align: center;
      padding: 36px; } }

#bloc-sponsors {
  margin-top: 20px;
  margin-bottom: 20px;
  /*
  .slick-prev {
    left:50%;
    bottom: -50px;
    top: auto;
    transform: translate(-100%, 150%);
    z-index: 90;
    background: #ffffff;
    padding-left: 80px;
    padding-right: 30px;
  }


  .slick-next {
    right:50%;
    bottom: -50px;
    top: auto;
    transform: translate(100%, 150%);
    z-index: 90;
    background: #ffffff;
    padding-right: 80px;
    padding-left: 30px;
  }
  */ }
  #bloc-sponsors h1 {
    margin: 0;
    margin-bottom: 20px;
    font-size: 17px;
    font-weight: 700;
    text-transform: uppercase; }
  #bloc-sponsors .slick-prev:before {
    content: "\F053"; }
  #bloc-sponsors .slick-next:before {
    content: "\F054"; }
  #bloc-sponsors .slick-prev:before, #bloc-sponsors .slick-next:before {
    color: #4a4a4a;
    opacity: 1;
    font-family: "Font Awesome 5 Pro";
    font-size: 30px; }
  #bloc-sponsors .slick-prev, #bloc-sponsors .slick-next {
    width: 30px;
    height: 61px; }
  #bloc-sponsors .slick-prev {
    left: -18px; }
  #bloc-sponsors .slick-next {
    right: -18px; }
  #bloc-sponsors .slick-slide {
    padding: 10px; }

@media (min-width: 992px) {
  #bloc-sponsors {
    margin-top: 70px;
    margin-bottom: 87px; }
    #bloc-sponsors h1 {
      font-size: 30px;
      letter-spacing: 1px;
      margin-bottom: 30px; }
    #bloc-sponsors .slick-prev:before, #bloc-sponsors .slick-next:before {
      font-size: 60px; }
    #bloc-sponsors .slick-prev {
      left: -65px; }
    #bloc-sponsors .slick-next {
      right: -65px; }
    #bloc-sponsors .slick-slide {
      padding: 20px; } }

#page-faq {
  /*
  margin-top: -100px;
  height: 640px;
  padding-top:170px;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;

  .container {
    text-align: center;
    color:#fff;

    h1, h2 {
      text-transform: uppercase
    }

    h1 {
      margin-top: 40px;
      margin-bottom: 20px;
      font-size: 35px;
      font-weight:$extraBold;
    }

    h2 {
      margin: 0;
      font-size: 25px;
      font-weight:$bold;
      line-height: 40px;
    }

    .counter {
      margin-top: 40px;
      text-transform: uppercase;
      color: $jauneMilan;
      border: 4px solid $jauneMilan;
      font-size: 30px;
      font-weight:$extraBold;
      width: 260px;
      height: 70px;
      line-height: 64px;
      margin: 40px auto;
    }
  }
  */ }
  #page-faq .intro {
    height: 400px;
    background-color: #ECC81A;
    background-repeat: no-repeat;
    background-position: top -190px center;
    margin-bottom: 90px; }
    #page-faq .intro h1 {
      font-size: 25px;
      font-weight: 700;
      margin: 0;
      letter-spacing: 1.39px;
      line-height: 40px;
      text-transform: uppercase;
      color: #ffffff;
      letter-spacing: 1px; }
    #page-faq .intro h2 {
      font-size: 18px;
      font-weight: 600;
      margin: 0;
      line-height: 40px;
      margin-bottom: 40px;
      letter-spacing: 1px; }
  #page-faq h3 {
    font-size: 20px;
    font-weight: 700;
    margin: 0;
    line-height: 40px;
    letter-spacing: 1.11px;
    border-top: 5px solid #000;
    padding-left: 5px; }
  #page-faq .questions {
    margin-bottom: 90px; }
  #page-faq .question {
    border-bottom: 2px solid #000; }
    #page-faq .question:first-of-type {
      border-top: 2px solid #000; }
    #page-faq .question .btn {
      position: relative;
      color: #38373B;
      font-size: 16px;
      font-weight: 600;
      margin: 0;
      padding: 20px 0;
      line-height: 40px;
      letter-spacing: 0.89px;
      border: 0;
      display: block;
      width: 100%;
      border-radius: 0;
      text-align: left;
      padding-right: 30px; }
      #page-faq .question .btn:after {
        content: '';
        width: 18px;
        height: 18px;
        position: absolute;
        right: 0;
        top: 31px;
        background: url(/static/media/plus.918405ea.svg) center center no-repeat;
        transition: -webkit-transform 300ms ease-in-out;
        transition: transform 300ms ease-in-out;
        transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
        -webkit-transform: rotate(135deg);
                transform: rotate(135deg); }
      #page-faq .question .btn.collapsed:after {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg); }
      #page-faq .question .btn:hover {
        text-decoration: none; }
    #page-faq .question .reponse {
      font-size: 12px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0.6px; }

#page-presse h1 {
  text-transform: uppercase;
  margin: 40px 0 40px 0;
  color: #174395;
  font-size: 25px; }

#page-presse .item {
  height: 420px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  border-bottom: 1px solid #174395;
  padding-bottom: 12px;
  margin-bottom: 50px; }
  #page-presse .item a, #page-presse .item a:hover  {
    text-decoration: none; }
  #page-presse .item a.title {
    color: #174395;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 25px;
    text-transform: uppercase;
    margin-top: 17px;
    margin-bottom: 18px; }
  #page-presse .item a.download {
    color: #174395;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 15px;
    text-transform: uppercase; }

@media (min-width: 992px) {
  #page-presse h1 {
    margin: 70px 0 42px 0;
    font-size: 30px; } }

.static-page {
  padding-top: 10px;
  padding-bottom: 30px; }
  .static-page h1 {
    margin: 50px 0; }

.svgicon {
  fill: red; }

#bloc-header-register {
  height: 420px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover; }
  #bloc-header-register .container {
    /*height: 100%;*/
    /*text-align: center;*/
    color: #fff;
    /*display: flex;
        flex-direction: column;
        justify-content: center;*/ }
    #bloc-header-register .container h1, #bloc-header-register .container h2 {
      text-transform: uppercase; }
    #bloc-header-register .container h1 {
      margin: 0;
      color: #FFFFFF;
      font-size: 18px;
      font-weight: 700;
      letter-spacing: 1px;
      line-height: 40px;
      padding-top: 200px; }
    #bloc-header-register .container h2 {
      max-width: 630px;
      margin: 0;
      font-size: 22px;
      font-weight: 700;
      line-height: 40px;
      letter-spacing: 1px; }

#bloc-intro-register h1 {
  text-transform: uppercase;
  text-align: center;
  margin: 0;
  color: #000000;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 40px;
  padding-top: 35px;
  padding-bottom: 70px; }

#bloc-types-register {
  padding-bottom: 130px;
  position: relative; }
  #bloc-types-register .type {
    cursor: pointer;
    height: 220px;
    border: 2px solid #174395;
    text-align: center;
    padding-top: 30px; }
    #bloc-types-register .type .picto {
      padding-bottom: 20px; }
      #bloc-types-register .type .picto .black {
        display: inline-block; }
      #bloc-types-register .type .picto .blue {
        display: none; }
    #bloc-types-register .type:hover .picto .black {
      display: none; }
    #bloc-types-register .type:hover .picto .blue {
      display: inline-block; }
    #bloc-types-register .type h3 {
      color: #000000;
      font-weight: 400;
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 19px; }
    #bloc-types-register .type h2 {
      color: #000000;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 1px;
      line-height: 25px;
      padding: 0 40px; }
    #bloc-types-register .type:hover, #bloc-types-register .type.current {
      border: 5px solid #174395;
      background-color: rgba(23, 67, 149, 0.1);
      padding-top: 27px; }
      #bloc-types-register .type:hover h2, #bloc-types-register .type.current h2 {
        color: #174395; }
  #bloc-types-register form {
    max-width: 550px; }
    #bloc-types-register form h2 {
      color: #000000;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 1.13px;
      line-height: 30px;
      text-align: center;
      padding-top: 70px; }
    #bloc-types-register form h3 {
      color: #174395;
      font-size: 18px;
      font-weight: 700;
      letter-spacing: 1px;
      line-height: 22px;
      text-align: left;
      padding-bottom: 20px;
      padding-top: 70px; }
    #bloc-types-register form button.btn-link {
      display: block;
      position: relative; }
      #bloc-types-register form button.btn-link:hover, #bloc-types-register form button.btn-link:focus {
        text-decoration: none; }
      #bloc-types-register form button.btn-link:before {
        content: '';
        position: absolute;
        background-color: #D8D8D8;
        height: 1px;
        width: 175px;
        bottom: 0;
        left: 13px;
        display: none; }
      #bloc-types-register form button.btn-link .fa-chevron-down {
        padding-left: 20px;
        display: none; }
      #bloc-types-register form button.btn-link .fa-chevron-up {
        padding-left: 20px;
        display: inline-block; }
      #bloc-types-register form button.btn-link.collapsed .fa-chevron-down {
        display: inline-block; }
      #bloc-types-register form button.btn-link.collapsed .fa-chevron-up {
        display: none; }
      #bloc-types-register form button.btn-link.collapsed:before {
        display: block; }
    #bloc-types-register form .field-button {
      display: block;
      margin-top: 70px; }
    #bloc-types-register form .field-checkbox {
      display: block;
      padding-top: 20px; }
      #bloc-types-register form .field-checkbox label {
        display: inline; }
    #bloc-types-register form .field-checkboxes {
      display: block;
      padding-top: 20px; }
      #bloc-types-register form .field-checkboxes h4 {
        margin-bottom: 40px; }
      #bloc-types-register form .field-checkboxes .label {
        margin-bottom: 30px;
        display: inline-block; }
      #bloc-types-register form .field-checkboxes label {
        font-size: 13px;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-justify-content: flex-start;
                justify-content: flex-start; }
    #bloc-types-register form .field-radio {
      display: block; }
      #bloc-types-register form .field-radio span {
        display: block;
        padding-top: 20px;
        padding-bottom: 10px; }
      #bloc-types-register form .field-radio label {
        margin-right: 30px; }
      #bloc-types-register form .field-radio#city_and_mufpp {
        display: -webkit-flex;
        display: flex;
        padding-top: 20px;
        padding-bottom: 10px; }
        #bloc-types-register form .field-radio#city_and_mufpp .label {
          display: none; }
    #bloc-types-register form h4 {
      color: #174395;
      font-size: 15px;
      font-weight: 600;
      letter-spacing: 0.94px;
      line-height: 19px;
      padding-top: 20px; }
    #bloc-types-register form input, #bloc-types-register form select, #bloc-types-register form textarea {
      background: #ffffff; }
    #bloc-types-register form select {
      font-weight: 400;
      color: #38373B; }
    #bloc-types-register form #edit-participants-0-legal,
    #bloc-types-register form #edit-participants-1-legal,
    #bloc-types-register form #edit-participants-2-legal {
      color: #174395;
      font-style: italic;
      font-size: 14px;
      margin-top: 18px;
      margin-bottom: 30px;
      padding-top: 15px;
      border-top: 1px solid #D8D8D8; }
  #bloc-types-register .thank {
    padding-top: 80px;
    padding-bottom: 40px;
    min-height: 200px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
    text-align: center;
    color: #174395;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 28px; }
    #bloc-types-register .thank .fal {
      font-size: 80px; }
    #bloc-types-register .thank .btn-link {
      text-decoration: underline;
      color: #174395; }
  #bloc-types-register .loading {
    color: #174395;
    width: 100%; }

/*
@media (min-width: 992px) {
    #bloc-header-register {

        .container {
            h1 {
                font-size: 25px;
                line-height: 36px;
                letter-spacing: 1px;
            }

            h2 {
                font-size: 14px;
                line-height: 28px;
                
                margin-top: 20px;
                margin-left: 150px;
                margin-right: 150px;
            }
        }    
    }
}
*/
#page-program {
  padding-top: 40px;
  padding-bottom: 40px; }
  #page-program .submenu {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    text-align: center;
    margin-bottom: 40px; }
    #page-program .submenu .nav-link {
      text-transform: uppercase;
      color: #000000;
      font-weight: 700;
      padding: .5rem 2px;
      font-size: 18px;
      line-height: 34px; }
      #page-program .submenu .nav-link.active {
        color: #174395;
        border-bottom: 4px solid #174395; }
  #page-program .photo {
    text-align: center; }
    #page-program .photo .img {
      width: 100%;
      height: auto;
      max-width: 400px; }
  #page-program .speaker {
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-bottom: 40px;
    max-width: 400px;
    margin: 0 auto; }
    #page-program .speaker .infos .name {
      text-transform: uppercase;
      color: #e6895c;
      font-weight: 700;
      line-height: 40px;
      margin-bottom: 6px;
      font-size: 15px; }
    #page-program .speaker .infos .field_titre {
      font-size: 12px;
      color: #adadad;
      font-weight: 500; }
    #page-program .speaker .infos .field_location {
      font-size: 12px;
      color: #adadad;
      line-height: 20px;
      font-weight: 500;
      margin-bottom: 20px; }
    #page-program .speaker .infos .field_prix {
      font-size: 12px;
      color: #38373b;
      font-weight: 400;
      margin-bottom: 20px; }
    #page-program .speaker .infos .field_body {
      font-size: 12px;
      color: #adadad;
      font-weight: 600;
      margin-bottom: 20px; }
      #page-program .speaker .infos .field_body p {
        margin: 0;
        line-height: 25px; }
    #page-program .speaker .infos .field_citation {
      font-size: 12px;
      color: #adadad;
      font-style: italic;
      font-weight: 400;
      margin-bottom: 20px; }

@media (min-width: 992px) {
  #page-program {
    padding-top: 80px;
    padding-bottom: 60px; }
    #page-program .submenu {
      margin-bottom: 60px;
      -webkit-flex-direction: row;
              flex-direction: row; }
      #page-program .submenu .nav-link {
        margin-right: 62px; }
    #page-program .speaker {
      display: -webkit-flex;
      display: flex;
      -webkit-flex: 1 1 auto;
              flex: 1 1 auto;
      -webkit-flex-direction: row;
              flex-direction: row;
      margin-bottom: 40px; }
    #page-program .photo .img {
      width: 200px;
      height: auto; }
    #page-program .infos {
      padding-left: 20px; } }

