#bloc-abientot {
  height: 450px;
	background-repeat: no-repeat;
	background-position: center center;
  background-size: cover;

  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    font-weight: $bold;
    font-size: 30px;
    text-transform: uppercase;
    color:#ffffff;
    margin-top:30px;
    letter-spacing: 1px;
  }

  h2 {
    font-weight: $medium;
    font-size: 22px;
    text-transform: uppercase;
    color:#ffffff;
    margin-top:30px;
    letter-spacing: 1px;
  }

  .navbar-nav {
    margin-top:10px;
    flex-direction: row;
    justify-content: center;

    .nav-item {
      margin-left:12px;
      margin-right: 12px;

      a {
        color:#ffffff;
      }
    }
  }
}