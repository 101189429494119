header {
  position: fixed;
  left: 0;
  right: 0;
  color: #fff;
  transition: background 600ms ease-in-out;
  padding-bottom: 10px;
  min-height: 90px;
  z-index: 2018;

  .navbar {
    padding: 0;
    text-align: center;
  }

  .navbar-brand {
    padding: 0;

    img {
      max-width: 200px;
    }
  }

  .navbar-collapse {
    padding-top:0px;
  }

  .navbar-light .navbar-nav .nav-link {
    &.nav-main {
      &.active{
        font-weight: $bold;
      }
    }
  } 

  .navbar-light .navbar-nav {
    .nav-item {
      .nav-link {
        font-size: 14px;
        font-weight: $medium;
        letter-spacing: 1.16px;
        color:#fff;
        position:relative;
      }

      &.dropdown {
        .nav-link {
          font-size: 16px;
          font-weight: $bold;
        }
      }
    }
  }

  .navbar-secondary {
    flex-direction: row;
    justify-content: center;

    .nav-item {
      margin:0 10px;
    }

    .dropdown-menu {
      min-width: auto;

      .dropdown-item {
        padding: .25em 1rem;
      }


      .dropdown-toggle::after {
        position: absolute;
        left: 50%;
        bottom: 0px;
        margin: 0;
        margin-left: -6px;
      }      
    }
  }

  &.opaque {
    background: #fff;
    box-shadow: 0px 2px 4px rgba(127, 157, 157, 0.5);

    .navbar-brand img {
      box-shadow: 0px 0px 10px -2px #aaa;
      border-radius: 0 0 4px 4px;
    }
    .navbar-light .navbar-nav .nav-link {
      color:#000;
    }

    .navbar-primary {
      .nav-cta {
        border-color:#000000;

        &:hover {
          border-color:$vertMilan;
          .nav-main {
            color:$vertMilan !important;
          }
        }
      }    
    }
  }
}

@media (min-width: 992px) {
  header {
    .navbar-collapse {
      padding-top:0px;
    }

    .navbar-brand {
      margin-right: 0;

      img {
        max-width: none;
      }
    }

    .navbar-light .navbar-nav .nav-link {
      &.nav-main {
        &:after{
          content: '';
          position: absolute;
          left:70px;
          right:70px;
          bottom:0;
          height:3px;
          background: #000000;
          transition: 250ms left ease-in-out, 250ms right ease-in-out;
        }

        &.active:after, &:hover:after{
          left:17px;
          right:17px;
        }
      }

      &:hover {
        color:#000;
      }
    }  
    
    .navbar-primary {
      .nav-item {
        margin: 0 11px;
      }

      .nav-cta {
        border:1px solid #ffffff;
        border-radius: 12px;
        
        &:hover {
          border-color:#000000;
        }
        
        .nav-main {
          font-weight: $semiBold !important;
          &:after {
            display: none !important;
          }
        }
      }
    }

    .navbar-secondary {
      .nav-item {
        margin:0;
      }

      &.navbar-nav .nav-item {
        &.dropdown {
          .nav-link {
            padding-top: 10px;
          }
        }

        .nav-link {
          font-size: 20px;
        }
      }
    }    
  }
}