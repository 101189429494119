#bloc-header-pacte {
  height: 557px;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;

  .container {
    height: 100%;
    text-align: center;
    color:#fff;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1, h2 {
      text-transform: uppercase
    }

    h1 {
      margin:0;
      font-size: 18px;
      font-weight:$extraBold;
      letter-spacing: 2,08px;
      line-height: 27px;
    }

    h2 {
      margin: 0;
      margin-top: 40px;
      font-size: 13px;
      font-weight:$semiBold;
      line-height: 18px;
      letter-spacing: 1.17px;
    }
  }

}


@media (min-width: 992px) {
  #bloc-header-pacte {

    .container {
      h1 {
        font-size: 25px;
        line-height: 36px;
        letter-spacing: 1px;
      }

      h2 {
        font-size: 14px;
        line-height: 28px;
        
        margin-top: 20px;
        margin-left: 150px;
        margin-right: 150px;
      }
    }    
  }
}