#bloc-mapmonde {
  
  padding: 20px 0;
  border-top: 2px solid $jauneMilan;
  border-bottom: 2px solid $jauneMilan;

  .row {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  p {
    margin:0;
  }

  a {
    margin-top: 25px;
  }

  .cta {
    margin: 0 auto;
    background-color: rgba(240,198,116,0.79);

    color: #000000;
    font-size: 10px;
    font-weight: $bold;
    letter-spacing: 1px;
    line-height: 30px;
    text-align: center;
    padding:20px;
  }  
}

@media (min-width: 992px) {
  #bloc-mapmonde {

    padding: 20px 0;
    border-top: 2px solid $jauneMilan;
    border-bottom: 2px solid $jauneMilan;

    .row {
      height: 560px;
      background-repeat: no-repeat;
      background-position: center center;      
    }

    .col {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .cta {
      width: 535px;
      margin: 0 auto;
      background-color: rgba(240,198,116,0.79);

      color: #000000;
      font-size: 16px;
      font-weight: $bold;
      letter-spacing: 1px;
      line-height: 30px;
      text-align: center;
      padding:36px;
    }
  }  
}