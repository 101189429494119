#page-presse {
    h1 {
        text-transform: uppercase;
        margin: 40px 0 40px 0;
        color: $bleu3m;
        font-size: 25px;
    }

    .item {
        height: 420px;
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid $bleu3m;
        padding-bottom: 12px;
        margin-bottom: 50px;

        a, a:hover {
            text-decoration: none;
        }

        a.title {
            color: $bleu3m;
            font-size: 16px;
            font-weight: $semiBold;
            letter-spacing: 1px;
            line-height: 25px;
            text-transform: uppercase;
            margin-top: 17px;
            margin-bottom: 18px;
        }

        a.download {
            color: $bleu3m;
            font-size: 12px;
            font-weight: $semiBold;
            letter-spacing: 1px;
            line-height: 15px;
            text-transform: uppercase;
        }        
    }
}

@media (min-width: 992px) {
    #page-presse {
        h1 {
            margin: 70px 0 42px 0;
            font-size: 30px;
        }
    }
}