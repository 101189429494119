.svgicon {
    fill:red;
}

#bloc-header-register {
    height: 420px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    .container {
        /*height: 100%;*/
        /*text-align: center;*/
        color:#fff;
        /*display: flex;
        flex-direction: column;
        justify-content: center;*/

        h1, h2 {
            text-transform: uppercase
        }

        h1 {
            margin: 0;
            color: #FFFFFF;
            font-size: 18px;
            font-weight: $bold;
            letter-spacing: 1px;
            line-height: 40px;
            padding-top: 200px;
        }

        h2 {
            max-width: 630px;
            margin: 0;
            font-size: 22px;
            font-weight:$bold;
            line-height: 40px;
            letter-spacing: 1px;
        }
    }
}

#bloc-intro-register {
    h1 {
        text-transform: uppercase;
        text-align: center;
        margin: 0;
        color: #000000;
        font-size: 18px;
        font-weight: $bold;
        letter-spacing: 1px;
        line-height: 40px;
        padding-top: 35px;
        padding-bottom: 70px;
    }
}

#bloc-types-register {

    padding-bottom: 130px;
    position: relative;

    .type {
        cursor: pointer;
        height: 220px;
        border: 2px solid #174395;
        text-align: center;
        padding-top: 30px;

        .picto {
            padding-bottom: 20px;

            .black {
                display:inline-block;
            }

            .blue {
                display:none;
            }

        }

        &:hover {
            .picto {
                .black {
                    display:none;
                } 

                .blue {
                    display:inline-block;
                }
            }
        }
        h3 {
            color: #000000;
            font-weight: $regular;
            font-size: 16px;
            letter-spacing: 1px;
            line-height: 19px;
        }

        h2 {
            color: #000000;
            font-size: 16px;
            font-weight: $bold;
            letter-spacing: 1px;
            line-height: 25px;
            padding: 0 40px;
        }

        &:hover, &.current {
            border: 5px solid #174395;
            background-color: rgba(23,67,149,0.1);
            padding-top: 27px;

            h2 {
                color: $bleu3m;
            }
        }
    }

    form {
        max-width: 550px;

        h2 {
            color: #000000;
            font-size: 18px;
            font-weight: 600;
            letter-spacing: 1.13px;
            line-height: 30px;
            text-align: center;
            padding-top: 70px;
        }

        h3 {
            color: $bleu3m;
            font-size: 18px;
            font-weight: $bold;
            letter-spacing: 1px;
            line-height: 22px;
            text-align: left;
            padding-bottom: 20px;
            padding-top:70px;             
        }

        button.btn-link {
            display:block;
            position:relative;


            &:hover, &:focus {
                text-decoration: none;
            }

            &:before {
                content:'';
                position:absolute;
                background-color: #D8D8D8;
                height: 1px;
                width: 175px;
                bottom:0;
                left:13px;
                display:none;
            }

            .fa-chevron-down {
                padding-left: 20px;
                display:none;
            }

            .fa-chevron-up {
                padding-left: 20px;
                display:inline-block;
            } 

            &.collapsed {
                .fa-chevron-down {
                    display:inline-block;
                }

                .fa-chevron-up {
                    display:none;
                }  

                &:before {
                    display: block;
                }
            }
        }

        .field-button {
            display: block;
            margin-top: 70px;
        }

        .field-checkbox {
            display: block;
            padding-top: 20px;

            label {
                display: inline;
            }
        }

        .field-checkboxes {
            display: block;
            padding-top: 20px;

            h4 {
                margin-bottom: 40px;
            }

            .label {
                margin-bottom: 30px;
                display: inline-block;
            }

            label {
                font-size: 13px;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;            
            }
        }
        

        .field-radio {
            display: block;

            span {
                display:block;
                padding-top: 20px;
                padding-bottom: 10px;
            }

            label {
                margin-right: 30px;
            }

            &#city_and_mufpp {

                display: flex;
                padding-top: 20px;
                padding-bottom: 10px;

                .label {
                    display:none;
                }
            }
        }

        h4 {
            color: $bleu3m;
            font-size: 15px;
            font-weight: 600;
            letter-spacing: 0.94px;
            line-height: 19px;
            padding-top: 20px;
            
        }

        input, select, textarea {
            background:#ffffff;
        }        

        select {
            font-weight: $regular;
            color: #38373B;
        }

        #edit-participants-0-legal,
        #edit-participants-1-legal,
        #edit-participants-2-legal
        {
            color:$bleu3m;
            font-style: italic;
            font-size: 14px;
            margin-top: 18px;
            margin-bottom: 30px;
            padding-top: 15px;
            border-top: 1px solid #D8D8D8;
        }
    }

      
    .thank {
    
        padding-top: 80px;
        padding-bottom: 40px;
    
        .fal {
          font-size: 80px;
        }
    
        .btn-link {
          text-decoration: underline;
          color: $bleu3m;
        }
    
        min-height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
      
        text-align: center;
        color: $bleu3m;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 1px;
        line-height: 28px;;  
    }
    
    .loading{
        color: $bleu3m;
        width: 100%;
    }    
}
/*
@media (min-width: 992px) {
    #bloc-header-register {

        .container {
            h1 {
                font-size: 25px;
                line-height: 36px;
                letter-spacing: 1px;
            }

            h2 {
                font-size: 14px;
                line-height: 28px;
                
                margin-top: 20px;
                margin-left: 150px;
                margin-right: 150px;
            }
        }    
    }
}
*/