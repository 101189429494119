#bloc-welcome {
  margin-top: -90px;
  padding-top:100px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding-bottom: 20px;

  .container {
    text-align: center;
    color:#fff;

    h1, h2 {
      text-transform: uppercase
    }

    h1 {
      margin:0;
      margin-top: 40px;
      font-size: 13px;
      font-weight:$extraBold;
    }

    h2 {
      margin: 0;
      margin-top: 20px;
      font-size: 18px;
      font-weight:$bold;
      line-height: initial;
    }

    h3 {
      margin: 0;
      margin-top: 20px;
      font-size: 18px;
      font-weight:$semiBold;
      line-height: 40px;
      letter-spacing: 1px
    }

    .counter {
      margin-top: 40px;
      text-transform: uppercase;
      color: $jauneMilan;
      border: 4px solid $jauneMilan;
      font-size: 30px;
      font-weight:$extraBold;
      width: 260px;
      height: 70px;
      line-height: 64px;
      margin: 40px auto;
      letter-spacing: 2px;
    }
  }
}

@media (min-width: 992px) {
  #bloc-welcome {
    margin-top: -100px;
    height: 640px;
    padding-top:130px;

    .container {
      h1 {
        font-size: 20px;
        letter-spacing: 1px;
      }

      h2 {
        font-size: 35px;
        line-height: 40px;
        letter-spacing: 1px;
        margin-left: 90px;
        margin-right: 90px;
      }
    }
  }
}