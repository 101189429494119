

#bloc-vision {
  background: #F8F8F8;

  .vignette {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    margin-bottom: 15px;

    height: 350px;
    padding: 60px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    text-align: center;
    color:#fff;

    position:relative;

    &:after {
      content: '';
      position: absolute;
      z-index: 10;
      top:0;
      left:0;
      right: 0;
      bottom: 0;
      background: rgba(115,145,85,.3);
    }


    .big {
      z-index: 20;
      font-size: 50px;
      font-weight: $bold;
      letter-spacing: 5px;
      line-height: 28px;
      margin-bottom: 40px;
    }

    .small {
      z-index: 20;
      font-size: 20px;
      font-weight: $semiBold;
      letter-spacing: 1.25px;
      line-height: 30px;
    }
  }

  .row-2 .col-lg-6:first-child {
    order: 2;
  }  
}


@media (min-width: 992px) {
  #bloc-vision {
    .offset-image {
      margin-top: 124px;
    }
 
    .row-2 {
      margin-top: -94px;

      .btn {
        margin-bottom: 60px;
      }
    }  

    .vignette {
      margin-bottom: 0;
    }

    .row-2 .col-lg-6:first-child {
      order: initial;
    }
  }
}