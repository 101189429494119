#bloc-objectifs {
  padding-top: 20px;
  padding-bottom: 20px;

  text-transform: uppercase;
  color: #000000;
  font-size: 13px;
  letter-spacing: 1px;
  line-height: 23px;

  h1 {
    color: #000000;
    font-size: 13px;	
    font-weight: $bold;
    letter-spacing: 1px;
    line-height: 23px;
  }
  
  .presentation {
    border-left:11px solid $jauneMilan;
    background:#ffffff;
    padding: 8px 14px;
  }
}

@media (min-width: 992px) {
  #bloc-objectifs {
    padding-top: 80px;
    padding-bottom: 70px;
  
    text-align: justify;
    font-size: 16px;
    line-height: 30px;
  
    h1 {
      font-size: 16px;	
      line-height: 30px;
    }
    
    .presentation {
      border-left:21px solid $jauneMilan;
      padding: 16px 32px;
    }
  }
}